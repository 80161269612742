<template>
  <div class="homePage">
    <el-container>
      <el-header>
        <div class="header">
          <div class="headerLeft">培训后台管理系统</div>
          <div class="headerRight">
            <el-popover
              placement="top-start"
              width="80"
              trigger="click"
            >
              <img
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                slot="reference"
                class="iconimg"
                alt="admin"
              />
              <template #default>
                <div class="headerTitle">
                  <el-button type="text" @click="exit">退出登录</el-button>
                </div>
              </template>
            </el-popover>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside width=" 200px">
          <el-row class="tac">
            <el-col :span="12">
              <el-menu
                :default-active="number"
                class="el-menu-vertical-demo"
                background-color="#1f2d3d"
                text-color="#fff"
                active-text-color="#0031c7"
                mode="vertical"
                :collapse="isCollapse"
              >
                <el-submenu index="1" background-color="#1f2d3d">
                  <template slot="title">
                    <span>培训系统管理</span>
                  </template>
                  <el-menu-item v-for="item in meanList" :key="item.id" :index="item.index" @click="classification(item.index)">{{item.name}}</el-menu-item>
                  <!-- <el-menu-item index="1-2" @click="ziliao"
                    >培训资料</el-menu-item
                  >
                  <el-menu-item index="1-3" @click="kaohe"
                    >培训考核</el-menu-item
                  >
                  <el-menu-item index="1-6" @click="kepu"
                    >考试记录</el-menu-item
                  >
                  <el-menu-item index="1-9" @click="user"
                    >用户管理</el-menu-item
                  >
                  <el-menu-item index="1-7" @click="feedback"
                    >问题反馈</el-menu-item
                  > -->
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main>
          <el-breadcrumb
            class="app-breadcrumb"
            separator-class="el-icon-arrow-right"
          >
            <el-breadcrumb-item v-for="item in levelList" :key="item.path">
              <router-link :to="item.redirect || item.path">{{
                item.meta.title
              }}</router-link>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isCollapse: false,
      levelList: null,
      number: '1-1',
      meanList:[
        {name:'分类管理',index:'1-1',id:1},
        {name:'培训资料',index:'1-2',id:2},
        {name:'培训考核',index:'1-3',id:3},
        {name:'考试记录',index:'1-4',id:4},
        {name:'用户管理',index:'1-5',id:5},
        {name:'问题反馈',index:'1-6',id:6}
      ]
    }
  },
  created () {  
    document.title = '培训后台管理系统'
    const path = this.$route.path
    if (path == '/homePage/training/training-ziliao') {
      // 培训资料
      this.number = '1-2'
    } else if (path == '/homePage/training/training-kh') {
      // 
      this.number = '1-3'
    } else if (path == '/homePage/training/training-kp') {
      // 考试记录
      this.number = '1-4'
    } else if (path == '/homePage/training/training-feedback') {
      // 问题反馈
      this.number = '1-6'
    } else if (path == '/homePage/training/training-user') {
      // 用户管理
      this.number = '1-5'
    } else if (path == '/homePage/training/training-classification') {
      // 分类管理
      this.number = '1-1'
    }
  },
  methods: {
    // 面包屑组件使用
    getBreadcrumb () {
      let matched = this.$route.matched.filter(item => item.name)
      const first = matched[0]
      if (first && first.name !== '首页') {
        matched = [{ path: 'training-classification', meta: { title: '首页' } }].concat(
          matched
        )
      }
      this.levelList = matched
    },
    exit () {
      this.$store.commit('setIsLogin', false)
      this.$router.push('/')
    },
    classification(index) {
      switch (String (index)) {
        case '1-1':
          console.log(index);
          this.$router.push('/homePage/training/training-classification')
          break;
        case '1-2':
          this.$router.push('/homePage/training/training-ziliao')
          break;
        case '1-3':
          this.$router.push('/homePage/training/training-kh')
          break;
        case '1-4':
          this.$router.push('/homePage/training/training-kp')
          break;
        case '1-5':
          this.$router.push('/homePage/training/training-user')
          break;
        case '1-6':
          this.$router.push('/homePage/training/training-feedback')
          break;
      }
    }
  },
  mounted () {
    this.getBreadcrumb()
  },
  watch: {
    $route (to, from) {
      this.getBreadcrumb()
    }
  }
}
</script>
<style lang="less" scoped>
.homePage {
  height: 100%;
  .tac .el-row {
    width: 200px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .headerLeft{
      line-height: 60px;
      font-size: 28px;
    }
    .headerRight {
      .iconimg {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        // line-height: 94px;
      }
      ::v-deep .el-popover{
        text-align: center;
        width: 60px !important;
        min-width: 60px !important;
      }
    }
  }
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 90px;
  padding: 0 60px;
}
.el-submenu__title {
  background-color: #304156 !important;
}
.el-aside {
  background-color: #304156;
  color: #333;
  text-align: center;
  line-height: 200px;
}
::-webkit-scrollbar{
  display: none;
}
.el-main {
  background-color: #fff;
  color: #333;
}
.el-container {
  height: 100%;
}
.el-menu-item {
  width: 200px;
}
.el-submenu {
  width: 200px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-width: 100px;
}
</style>
